import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import Modal from 'react-modal'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { useApollo } from '../lib/apolloClient'
import HelmetComponent from '../components/utility/HelmetComponent'
import Layout from '../components/layout/Layout/Layout'
import { AuthProvider } from '../contexts/auth'

import ym, { YMInitializer } from 'react-yandex-metrika'
import ReactGA from 'react-ga'

import '../styles/normalize.css'
import '../styles/fonts.css'
import '../styles/object-fit.css'
import '../styles/globals.css'
import '../styles/scss/uikit.scss'
import '../styles/scss/inputStyles.scss'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'plyr/dist/plyr.css'
import { WishlistProvider } from '../contexts/wishlist'
import { CartProvider } from '../contexts/cart'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

Modal.setAppElement('#__next')

const KMApp: NextPage<AppProps> = ({
  Component,
  pageProps: props,
  err,
}: AppProps & { err: Error }) => {
  const pageProps = props as any
  const apolloClient = useApollo(pageProps)
  const router = useRouter()

  const registerGooleAnaliticsRoute = (link) => {
    ReactGA.pageview(link)
  }

  useEffect(() => {
    setTimeout(() => {
      ReactGA.initialize('UA-82387942-1', { debug: false })
      registerGooleAnaliticsRoute(router.asPath)
    }, 2000)
    try {
      ym('hit', router.asPath)
      router.events.on('routeChangeComplete', (url) => {
        ym('hit', url)

        registerGooleAnaliticsRoute(url)
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
      scriptProps={{
        async: true,
        defer: true,
      }}
    >
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <CartProvider>
            <WishlistProvider>
              <Layout {...pageProps}>
                <HelmetComponent {...pageProps.seo} />
                <Component {...pageProps} err={err} />
              </Layout>
            </WishlistProvider>
          </CartProvider>
        </AuthProvider>
        <YMInitializer
          accounts={[32667085]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            ecommerce: 'dataLayer',
          }}
          version="2"
        />
      </ApolloProvider>
    </GoogleReCaptchaProvider>
  )
}

export default KMApp
